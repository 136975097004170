import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Section20.css';
import { ArrowRight } from 'lucide-react';

const Section20 = () => {
    return (
        <section className="s20-section">
            <div className="s20-container">
                <motion.h2 
                    className="s20-title"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    Sua Jornada de Transformação Começa Aqui
                </motion.h2>
                
                <div className="s20-content-wrapper">
                    <motion.div 
                        className="s20-video-wrapper"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        <div className="s20-video-container">
                        <iframe width="560" height="316" src="https://www.youtube.com/embed/8dBJVqvH2Z0?si=5xkQ-5POzxzcnIA9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                        </div>
                    </motion.div>
                    
                    <motion.a 
                        href="#inscreva-se" 
                        className="s20-button"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Quero participar do Reencontro <ArrowRight className="s20-arrow-icon" />
                    </motion.a>
                </div>
            </div>
        </section>
    );
};

export default Section20;