import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { motion, AnimatePresence } from 'framer-motion';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/Section13.css';
import '../styles/Section14.css';
import thumb1 from '../assets/Captura de tela 2024-10-17 193233.png'
import thumb2 from '../assets/Captura de tela 2024-10-17 193308.png'
import thumb3 from '../assets/Captura de tela 2024-10-17 193352.png'
// New thumbnail imports
import thumb4 from '../assets/Captura de tela 2025-03-14 160136.png'
import thumb5 from '../assets/Captura de tela 2025-03-14 160146.png'
import thumb6 from '../assets/Captura de tela 2025-03-14 160155.png'
import depoimento1 from '../assets/WhatsApp Image 2024-10-12 at 12.08.56.jpeg'
import depoimento2 from '../assets/WhatsApp Image 2024-10-12 at 12.08.57.jpeg'
import depoimento3 from '../assets/WhatsApp Image 2024-10-14 at 21.13.13.jpeg'
import depoimento4 from '../assets/WhatsApp Image 2024-10-17 at 16.45.24.jpeg'
import depoimento5 from '../assets/Depoimento 5.jpeg'
import depoimento6 from '../assets/Depoimento 6.jpeg'
import depoimento7 from '../assets/Depoimento 7.jpeg'
import depoimento8 from '../assets/Depoimento 8.jpeg'
import depoimento9 from '../assets/Depoimento 9.jpeg'
import depoimento10 from '../assets/Depoimento 10.jpeg'
import depoimento11 from '../assets/Depoimento 11.jpeg'
import depoimento12 from '../assets/Depoimento 12.jpeg'
// Import new images
import img1 from '../assets/img1.jpg'
import img2 from '../assets/img2.jpg'
import img3 from '../assets/img3.jpg'
import img4 from '../assets/img4.jpg'
import img5 from '../assets/img5.jpg'
import img6 from '../assets/img6.jpg'
import img7 from '../assets/img7.jpg'
import img8 from '../assets/img8.jpg'
import img9 from '../assets/img9.jpg'
import img10 from '../assets/img10.jpg'

const Section13 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const openModal = (video) => {
    setSelectedVideo(video);
    setIsModalOpen(true);
    // Prevent scrolling when modal is open
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
    // Re-enable scrolling when modal is closed
    document.body.style.overflow = 'auto';
  };

  const featuredTestimonials = [
    // Updated thumbnails for the new videos with isVertical flag
    { src: "https://www.youtube.com/embed/NBAsNGVH5bM?si=UEDU8iIOnCt5-ha9", title: 'Luana', thumbnail: thumb4, isVertical: true },
    { src: "https://www.youtube.com/embed/Vtij2w3i5LE?si=g0W-31gkpUsJZXgp", title: 'Kamila', thumbnail: thumb5, isVertical: true },
    { src: "https://www.youtube.com/embed/nRuEWIwHgJ0?si=aO6LoqUYvxWUQOEJ", title: 'Aruana', thumbnail: thumb6, isVertical: true },
    // Existing videos (horizontal)
    { src: "https://www.youtube.com/embed/DEW8jTUuvuk?si=3G5BKa8Z79ZTmc9b", title: 'Raiana', thumbnail: thumb1 },
    { src: "https://www.youtube.com/embed/JmG1rjehJy4?si=OuRwbJ9Q5wDm7pE3", title: 'Letícia', thumbnail: thumb2 },
    { src: "https://www.youtube.com/embed/_JQOLt2mk0U?si=xX4Ym9tM5U-zk_Sj", title: 'Andressa', thumbnail: thumb3 },
  ];

  const carouselImages = [
    // Add new images
    { src: img1, alt: 'Depoimento - Imagem 1' },
    { src: img2, alt: 'Depoimento - Imagem 2' },
    { src: img3, alt: 'Depoimento - Imagem 3' },
    { src: img4, alt: 'Depoimento - Imagem 4' },
    { src: img5, alt: 'Depoimento - Imagem 5' },
    { src: img6, alt: 'Depoimento - Imagem 6' },
    { src: img7, alt: 'Depoimento - Imagem 7' },
    { src: img8, alt: 'Depoimento - Imagem 8' },
    { src: img9, alt: 'Depoimento - Imagem 9' },
    { src: img10, alt: 'Depoimento - Imagem 10' },
    // Existing images
    { src: depoimento1, alt: 'Depoimento 1' },
    { src: depoimento2, alt: 'Depoimento 2' },
    { src: depoimento3, alt: 'Depoimento 3' },
    { src: depoimento4, alt: 'Depoimento 4' },
    { src: depoimento5, alt: 'Depoimento 5' },
    { src: depoimento6, alt: 'Depoimento 6' },
    { src: depoimento8, alt: 'Depoimento 8' },
    { src: depoimento7, alt: 'Depoimento 7' },
    { src: depoimento9, alt: 'Depoimento 9' },
    { src: depoimento10, alt: 'Depoimento 10' },
    { src: depoimento11, alt: 'Depoimento 11' },
    { src: depoimento12, alt: 'Depoimento 12' }
  ];

  return (
    <section className="s13-section s13-dark-background" ref={sectionRef}>
      {isVisible && (
        <div className="s13-container">
          <motion.h2 
            className="s13-section-title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Depoimentos emocionantes!
          </motion.h2>
          
          {/* Replace static grid with Swiper carousel for videos */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            className="s13-video-carousel"
          >
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={30}
              slidesPerView={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              pagination={{ clickable: true }}
              autoplay={{ 
                delay: 6000,
                disableOnInteraction: false 
              }}
              loop={true}
              className="s13-video-swiper"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              {featuredTestimonials.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <motion.div 
                    className="s13-video-testimonial"
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <div 
                      className="s13-video-thumbnail-wrapper" 
                      data-vertical={testimonial.isVertical ? "true" : "false"}
                      onClick={() => openModal(testimonial)}
                    >
                      <img 
                        src={testimonial.thumbnail} 
                        alt={`${testimonial.title} thumbnail`} 
                        className="s13-video-thumbnail" 
                      />
                      <div className="s13-play-button">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="32" cy="32" r="32" fill="rgba(0,0,0,0.6)"/>
                          <path d="M42 32L26 42V22L42 32Z" fill="white"/>
                        </svg>
                      </div>
                    </div>
                    <h3 className="s13-video-title">{testimonial.title}</h3>
                  </motion.div>
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>

          <motion.div
            className="s13-carousel-container"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="s13-carousel-title">Mais Histórias Inspiradoras</h3>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'disabled_swiper_button'
              }}
              pagination={{ clickable: true }}
              autoplay={{ 
                delay: 5000,
                disableOnInteraction: false
              }}
              loop={true}
              className="s13-swiper"
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 25,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              {carouselImages.map((image, index) => (
                <SwiperSlide key={index}>
                  <motion.div 
                    className="s13-carousel-item"
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: "spring", stiffness: 300 }}
                  >
                    <img src={image.src} alt={image.alt} className="s13-carousel-image" />
                  </motion.div>
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </div>
      )}
      <div className="s14-container">
        <motion.div 
          className="s14-quote-box"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="s14-decorative-line s14-line-left"></div>
          <div className="s14-decorative-line s14-line-right"></div>
          <motion.div 
            className="s14-quote-icon s14-quote-open"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            "
          </motion.div>
          <motion.h2 
            className="s14-quote-text"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.6 }}
          >
            Muitas mulheres já decidiram reacender a luz do amor próprio, se você está aqui
            <span className="desktop-break"></span> então chegou a sua vez.
          </motion.h2>
          <motion.div 
            className="s14-quote-icon s14-quote-close"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            "
          </motion.div>
        </motion.div>
        <motion.div 
          className="s14-decorative-element s14-element-left"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.9, duration: 0.6 }}
        >
          ✦
        </motion.div>
        <motion.div 
          className="s14-decorative-element s14-element-right"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.9, duration: 0.6 }}
        >
          ✦
        </motion.div>
      </div>
      {/* Video Modal */}
      <AnimatePresence>
        {isModalOpen && selectedVideo && (
          <motion.div
            className="s13-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={closeModal}
          >
            <motion.div
              className={`s13-modal-content ${selectedVideo.isVertical ? 's13-vertical-modal' : ''}`}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              transition={{ duration: 0.4 }}
              onClick={(e) => e.stopPropagation()}
            >
              <button className="s13-modal-close" onClick={closeModal}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white"/>
                </svg>
              </button>
              <div className={`s13-modal-video-container ${selectedVideo.isVertical ? 's13-vertical-video-container' : ''}`}>
                <iframe
                  src={selectedVideo.src}
                  title={selectedVideo.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="s13-modal-video"
                ></iframe>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Section13;