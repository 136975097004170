import React from 'react';
import { motion } from 'framer-motion';
import '../styles/Section18.css';
import LauanaImage from '../assets/LAUANA (1).jpeg';

const Section17 = () => {
    const bioItems = [
        "Uma mulher apaixonada pelo desenvolvimento pessoal",
        "Psicóloga e Mentora de Mulheres",
        "Especialista em Autoconhecimento e Autoestima",
        "Mentora licenciada do método os 6 pilares da Autoestima",
        "6 anos consecutivos de experiência em atendimentos psicoterapêuticos e mentorias",
        "Mais de 12 anos trabalhando e realizando atividades sociais em aconselhamento, treinamento e atendimentos voluntários"
    ];

    return (
        <section className="s18-section">
            <div className="s18-container">
                <motion.div 
                    className="s7-image-frame"
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <img 
                        src={LauanaImage}
                        alt="Lauana Quirino" 
                        className="s18-lauana-image"
                    />
                    <div className="s18-image-decoration left"></div>
                    <div className="s18-image-decoration right"></div>
                </motion.div>
                
                <motion.h2 
                    className="s18-title"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    Quem é Lauana Quirino?
                </motion.h2>
                
                <motion.div 
                    className="s18-bio-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                >
                    {bioItems.map((item, index) => (
                        <motion.p 
                            key={index}
                            className="s18-bio-item"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.6 + index * 0.1, duration: 0.5 }}
                        >
                            {item}
                        </motion.p>
                    ))}
                </motion.div>
                
                <motion.div 
                    className="s18-quote"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.2, duration: 0.5 }}
                >
                    "Resgatar a autoestima em mulheres não é apenas o meu trabalho é a minha
                    missão, faz parte do meu propósito de vida."
                </motion.div>
            </div>
        </section>
    );
};

export default Section17;