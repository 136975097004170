import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowRight } from 'react-icons/fa';
import backgroundImage from '../assets/person-enjoying-time-nature.jpg';
import logoImage from '../assets/LOGO ATUAL .PNG';
import mainImage from '../assets/s1main.jpeg'; // Import the new image
import { ArrowRight } from 'lucide-react';
import '../styles/Section1.css';

const Section1 = () => {
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section className="s1-container">
      <div className="s1-background-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
      <div className="s1-content">
        <motion.div 
          className="s1-text-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.div 
            className="s1-logo-container"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="s1-logo-wrapper">
              <img 
                src={logoImage}
                alt="Logo Imersão" 
                className="s1-logo-image"
              />
            </div>
          </motion.div>
          
          {/* Add the main image below the logo */}
          <motion.div 
            className="s1-main-image-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <img 
              src={mainImage}
              alt="Imersão Main" 
              className="s1-main-image"
            />
          </motion.div>
          
          <div className="s1-description">
            <motion.p 
              className="s1-highlight"
              variants={textVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              Reencontre a mulher única e extraordinária que vive dentro de você.
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 0.8, duration: 0.8 }}
            >
              Nesta experiência transformadora você irá se reconectar com a sua verdadeira essência, a de uma mulher única e de grande valor, fará um mergulho profundo de resgate reacendendo ao máximo seu amor próprio e sua Autoestima.
            </motion.p>
            <motion.p
              variants={textVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 1.1, duration: 0.8 }}
            >
              O Reencontro não é somente uma Mentoria, ele é um virar de chaves. É um convite muito especial, uma oportunidade que você dará a si mesmo de se reconectar com a original identidade da mulher que de fato Deus te criou para ser: <br/> <span className='s1-glow'> amada, autêntica e realmente feliz. </span>
            </motion.p>
            <motion.p 
              className="s1-highlight"
              variants={textVariants}
              initial="hidden"
              animate="visible"
              transition={{ delay: 1.4, duration: 0.8 }}
            >
              "Você não chegou até aqui por acaso, permita-se."
            </motion.p>
          </div>
          <motion.a 
            href="#inscreva-se"
            className="s1-cta-button"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Quero participar do Reencontro <ArrowRight className="s3-arrow-icon" />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default Section1;