import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../styles/Section7.css';
import lauanaImage from '../assets/LAUANA.jpeg';
import frame from '../assets/WhatsApp_Image_2024-10-18_at_18.06.18-removebg-preview.png';

const Section7 = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { 
            opacity: 1,
            transition: { 
                duration: 0.8,
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <motion.section 
            ref={ref}
            className="s7-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="s7-container">
                <motion.h2 
                    className="s7-title"
                    variants={itemVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                >
                    E se você se der uma oportunidade?
                </motion.h2>
                <div className="s7-content">
                    <motion.div 
                        className="s7-image-column"
                        variants={itemVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                    >
                        <div className="s7-image-frame">
                            <img 
                                src={lauanaImage} 
                                alt="Lauana Quirino" 
                                className="s7-main-image"
                            />
                        </div>
                    </motion.div>
                    <motion.div 
                        className="s7-text-column"
                        variants={containerVariants}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                    >
                        <ul className="s7-opportunity-list">
                            {[
                                "De sarar feridas emocionais",
                                "De identificar padrões negativos e ressignificá-los",
                                "De resgatar a verdadeira identidade",
                                "De reconstruir sua Autoestima",
                                "De desenvolver valor próprio e Autoconfiança",
                                "De aprender a se amar de maneira incondicional",
                                "De realizar os próprios sonhos"
                            ].map((item, index) => (
                                <motion.li 
                                    key={index}
                                    variants={itemVariants}
                                >
                                    {item}
                                </motion.li>
                            ))}
                        </ul>
                        <motion.p 
                            className="s7-quote"
                            variants={itemVariants}
                        >
                            "O amor próprio exige a coragem de olhar para dentro e se
                            curar."
                        </motion.p>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section7;